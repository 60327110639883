.accordion {
  margin-bottom: 10px;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem;
  background: #FFF;
  color: #000;
}

.accordion-title {
  margin: 0;
  font-size: 1rem;
}

.accordion-icon {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  margin-left: 10px;
  transition: transform 0.2s ease;
}
.accordion-icon::before,
.accordion-icon::after {
  content: '';
  position: absolute;
  background-color: #000;
}
.accordion-icon::before {
  left: 0;
  top: 20px;
  width: 44px;
  height: 4px;
}
.accordion-icon::after {
  left: 20px;
  top: 0;
  width: 4px;
  height: 44px;
}

.accordion-icon.open {
  transform: rotate(45deg);
}


.accordion-content {
  overflow: hidden;
  transition: max-height 0.2s ease, padding .3s ease-in;
  will-change: max-height, padding;
  max-height: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.accordion-content.open {
  max-height: 1000px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.accordion-content-inner {
  padding: 20px;
  border-radius: 8px;
  background-color: #34353C;
}