.loader {
  margin: 50px;
}

/**
  * push-pop
  * lil push pop effect, push one side out and one side in
  * @author jh3y
*/
.push-pop {
  --duration: .85;
  height: 100px;
  width: 100px;
  position: relative;
  overflow: hidden;
}
.pop1 {
  height: 20px;
  width: 20px;
  position: absolute;
  -webkit-animation: push-pop-slide calc(var(--duration) * 1s) infinite alternate ease-in-out;
          animation: push-pop-slide calc(var(--duration) * 1s) infinite alternate ease-in-out;
  transform: translate(0, -100%);
  top: 100%;
  left: 0;
}
.pop1:after {
  -webkit-animation: push-pop-flip calc(var(--duration) * 1s) infinite alternate ease-in-out;
          animation: push-pop-flip calc(var(--duration) * 1s) infinite alternate ease-in-out;
  background: #f8de5e;
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
}
.pop2 {
  background: #f8de5e;
  height: 30px;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -100%);
  width: 20px;
}
.push-pop:after, .push-pop:before {
  -webkit-animation: push-pop-pushed calc(var(--duration) * 1s) alternate infinite ease;
          animation: push-pop-pushed calc(var(--duration) * 1s) alternate infinite ease;
  background: #f8de5e;
  bottom: 0;
  content: "";
  height: 40px;
  position: absolute;
  width: 20px;
}
.push-pop:before {
  animation: push-pop-pushed calc(var(--duration) * 1s) alternate-reverse infinite ease;
  left: 0;
}
.push-pop:after {
  right: 0;
}

@-webkit-keyframes push-pop-pushed {
  0%, 72.5% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 100%);
  }
}

@keyframes push-pop-pushed {
  0%, 72.5% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 100%);
  }
}
@-webkit-keyframes push-pop-flip {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(0, -80px) rotate(90deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}
@keyframes push-pop-flip {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(0, -80px) rotate(90deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}
@-webkit-keyframes push-pop-slide {
  to {
    transform: translate(0, -100%) translate(80px, 0);
  }
}
@keyframes push-pop-slide {
  to {
    transform: translate(0, -100%) translate(80px, 0);
  }
}