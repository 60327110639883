.banner2 {
  width: 100%;
  background: #FFF;
  transform-origin: left top;
  transform: scale(0, .1);
  animation: scaleXY 1.6s ease .4s forwards;
  will-change: transform;
  overflow: hidden;
}

.banner2-inner {
  width: 100%;
}

.banner2-content {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  opacity: 0;
  animation: fadeIn 1s ease-in 2.4s forwards;
  will-change: transform;
}

.banner2-text {
  margin: auto;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #000;
  padding-left: 20px;
}
.banner2-text:before {
  content: '';
  position: absolute;
  height: 36px;
  width: 6px;
  top: 0;
  left: 0;
  background-color: #239BCD;
}

.banner2-link {
  position: relative;
  display: block;
  padding: 5px 50px;
  text-align: center;
  background: linear-gradient(90deg, #298FD9 16%, #229ACC 70%);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;

  transform: translateY(-500px);
  animation: slideDown 1s ease forwards;
  will-change: transform;
  animation-delay: 1.4s;
}




.banner2-logo {
  position: relative;
  width: 50%;
  max-width: 200px;
  padding: 0 20px;
  background-position: center;
  background-size: cover;
}

.banner2-logo-img {
  width: 100%;
}



@media screen and (max-width: 991.99px) {

  .banner2-link {
    width: auto;
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media screen and (min-width: 992px) {
  

  .banner2 {
    margin-right: auto;
    margin-left: auto;
  }
  .banner2-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .banner2-logo {
    max-width: 280px;
  }


  .banner2-link {
    width: auto;
    padding: 14px 15px;
    font-size: 28px;
    line-height: 120%;
    width: 30%;
    color: #fff;
    border-radius: 10px;
  }
  

  .banner2-content {
    display: flex;
    align-items: center;
    border-radius: 18px;
  }

  .banner2-text {
    text-align: center;
    font-size: 28px;
    padding-left: 40px;
  }

  .banner2-text:before {
    width: 20px;
  }

}