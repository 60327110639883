.header {
  position: relative;
  z-index: 200;
  width: 100%;
  background-color: #2A2B2F;
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 14px;
}

.header__logo {
  margin-right: auto;
  width: 110px;
  display: block;
}
.header__main {
  font-size: 11px;
  color: #FFF;
  text-transform: uppercase;
  text-decoration: none;
  margin: auto;
}

@media screen and (min-width: 410px) {
  .header__logo {
    width: 120px;
  }
  .header__main {
    font-size: 14px;
  }
}

.header__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a2b2f;
}


.nav-item {
  font-size: 12px;
  font-family: inherit;
  padding: 4px;
  text-align: center;
  display: block;
  width: auto;
  flex-grow: 1;
  text-decoration: none;
  color: #FFF;
  background: 0;
  border: 1px solid #FFF;
  border-radius: 6px;
}
.nav-item.active {
  color: #000;
  background-color: #F8DE5E;
}


.dropdown-nav {
  position: relative;
}
.dropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  padding-top: 12px;
  width: 300px;
}
.dropdown-container {
  border: 1px solid #F8DE5E;
  border-radius: 8px;
  background: #2A2B2F;
  padding: 28px 0 25px;
}
.dropdown-container:before {
  content: '';
  position: absolute;
  left: 20px;
  top: 5px;
  width: 16px;
  height: 16px;
  background: #F8DE5E;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(45deg);
}
.dropdown__nav-item {
  text-align: center;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  transition: background-color .4s ease-out, color .4s ease;
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.dropdown__nav-item:hover {
  background-color: #4C4E56;
}
.dropdown__nav-item.active {
  background-color: #eee;
  color: #2A2B2F;
}

.dropdown-nav:hover > .dropdown {
  display: block;
}






@media screen and (max-width: 991px) {
  .header__nav {
    position: sticky;
    top: 0;
    z-index: 100;

    order: 3;
    width: 100%;
    gap: 7px;
    padding: 14px;
  }
  .header__btn {
    order: 2;
  }
}

@media screen and (min-width: 410px) {
  .header__btn,
  .nav-item {
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) {
  .header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }
  .header__logo {
    width: 152px;
  }
  .header__nav {
    width: auto;
    gap: 25px;
    margin-right: 25px;
  }
  .header__main {
    margin-right: 25px;
  }
  .header__btn,
  .nav-item {
    padding: 6px 20px;
    font-size: 16px;
  }

  .header__main {
    font-size: 16px;
  }
  .dropdown-container {
    border-width: 2px;
    border-radius: 20px;
  }
}
@media screen and (min-width: 1400px) {
  .header__btn,
  .nav-item {
    padding: 6px 30px;
    font-size: 20px;
  }
  .header__main {
    font-size: 20px;
  }
}