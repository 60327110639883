
.banner1 {
  background: #FFF;
  transform-origin: left top;
  transform: scale(0, .1);
  animation: scaleXY 1.6s ease .4s forwards;
  will-change: transform;
  overflow: hidden;
}
.banner1__container {
  padding: 10px 12px 20px;
}
.banner1__card {
  background: #45474B;
  border-radius: 10px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  transform: translateY(-500px);
  animation: slideDown 1s ease forwards;
  will-change: transform;
}

.banner1__card:nth-child(1) {
  animation-delay: 1.4s;
}
.banner1__card:nth-child(2) {
  animation-delay: 2.4s;
}
.banner1__card:nth-child(3) {
  animation-delay: 3.4s;
}

@keyframes slideDown {
  to {
    transform: translateY(0);
  }
}
@keyframes scaleX {
  to {
    transform: scaleX(1);
  }
}
@keyframes scaleXY {
  0% {
    transform: scale(0, .1);
  }
  20% {
    transform: scale(1, .1);
  }
  to {
    transform: scale(1, 1);
  }
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}




.banner1__card__text {
  font-weight: 500;
  font-size: 12px;
  margin: auto;
}

.banner1__card__int {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  margin-right: 10px;

  background: linear-gradient(141.15deg, #F8DE5E 22.31%, #FF7A00 82.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}



.banner1__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 13px;
  
}

.banner1__bottom > * {
  opacity: 0;
  animation: fadeIn 1s ease-in 3.8s forwards;
  will-change: transform;
}
.banner1__bottom__text {
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  max-width: 200px;
}
.banner1__bottom__btn {
  display: block;
  width: 116px;
  padding: 2px;
  text-align: center;
  border-radius: 7px;
  background: linear-gradient(265.39deg, #F8DE5E 19.66%, #FF7A00 87.49%); 
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #1E1F23;
}
.banner1__bottom__btn-inner {
  display: block;
  padding: 6px;
  background: #FFF;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}







@media screen and (min-width: 400px) {
  .banner1__card__text {
    font-size: 14px;
  }
  .banner1__card__int {
    font-size: 16px;
    margin-bottom: 13px;
  }
}




@media screen and (min-width: 768px) {
  .banner1__container {
    justify-content: center;
  }

  .banner1__card {
    flex-shrink: 0;
    width: 252px;
    padding: 20px 25px;
    border-radius: 20px;
    margin: 0;
    display: block;
  }
  .banner1__card__int {
    font-size: 30px;
  }
  .banner1__card__text {
    font-size: 18px;
  }

  .banner1__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }

  .banner1__bottom {
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    gap: 6px;
    position: relative;
    padding-left: 84px;
    margin: 0;
  }
  .banner1__bottom::before {
    content: '';
    display: block;
    width: 14px;
    position: absolute;
    left: 35px;
    top: 0;
    bottom: 0;
    background: #45474B;

    transform: scaleX(0);
    animation: scaleX .4s ease 3.6s forwards;
    will-change: transform;
  }

  .banner1__bottom__text {
    font-size: 18px;
    max-width: 248px;
  }

}	






@media screen and (min-width: 1200px) {
  .banner1__inner {
    flex-wrap: nowrap;
  }
  .banner1__card {
    border-radius: 27px;
  }

  .banner1__bottom__btn {
    display: block;
    width: auto;
    font-size: 16px;
  }
  .banner1__bottom__btn-inner {
    padding: 12px 30px;
  }
}