.broker {
	position: relative;
	background: #3E3E3E;
	border-radius: 10px;
}


.broker-logo {
  cursor: pointer;
  flex-shrink: 0;
  padding: 8px;
}

.broker-logo::after {
  content: 'Подробнее...';
  display: block;
	text-decoration: none;
	color: #fff;
	font-size: 12px;
  margin-left: 14px;
}
.broker-logo-img {
  width: 140px;
  height: 32px;
  margin: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}




.broker-cell {
  padding: 5px;
  text-align: center;
}




.broker-value {
  padding: 5px;
	background: #232323;
	border-radius: 10px;
	font-weight: 500;
  white-space: nowrap;
  min-width: 56px;
	font-size: 14px;
}
.broker-raw__value {
  display: inline-block;
  padding: 7px 12px;
}










.reg-rf::after,
.reg-rf-x::after {
  content: '';
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 17px;
  height: 17px;
  margin-left: 5px; 
}
.reg-rf::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC4zMTk4MjQiIHk9IjAuMjQ2MzM4IiB3aWR0aD0iMTYuODYyMSIgaGVpZ2h0PSIxNi44NjIxIiByeD0iMi40MDg4NyIgZmlsbD0iI0Y4REU1RSIvPgo8cGF0aCBkPSJNMi43MjkgNy40NDQ4N0M0LjQ5NjggOC43MDE1MyA4LjAzMjQgMTEuNDE3NCA4LjAzMjQgMTIuMjI3N0M4LjAzMjQgMTMuMjQwNSAxNS42MDg3IDEuNzA1NTIgMTcuMTgyMiAyLjcxODM1IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIuNDA4ODciLz4KPC9zdmc+Cg==);
}
.reg-rf-x::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC4zMTk4MjQiIHk9IjAuMDQ5MzE2NCIgd2lkdGg9IjE2Ljg2MjEiIGhlaWdodD0iMTYuODYyMSIgcng9IjIuNDA4ODciIGZpbGw9IiNGOERFNUUiLz4KPHBhdGggZD0iTTIuNzI5IDEuNjU1MjdMMTUuNTc2MyAxNC41MDI2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuOTk1NyIvPgo8cGF0aCBkPSJNMi43MjkgMTQuNTAyNEwxNS41NzYzIDEuNjU1MTUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS45OTU3Ii8+Cjwvc3ZnPgo=);
}




@media screen and (min-width: 1200px) {
  .reg-rf::after,
  .reg-rf-x::after {
    background-color: #232323;
    background-position: center;
    background-size: 50%;
    border-radius: 10px;
    width: 32px;
    height: 32px;
  }
  .reg-rf::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAzMyAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNjQ0NTMgMTIuMjgxOUM1LjI0OTAxIDE0LjkwNjcgMTIuNDU4IDIwLjU3OTMgMTIuNDU4IDIyLjI3MTdDMTIuNDU4IDI0LjM4NzIgMjcuOTA1NyAwLjI5NDE0NiAzMS4xMTQxIDIuNDA5NjMiIHN0cm9rZT0iI0Y4REU1RSIgc3Ryb2tlLXdpZHRoPSIzLjU5Mzg1Ii8+Cjwvc3ZnPgo=")
  }
  .reg-rf-x::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNTIyNDYgMS45NTMyNUwzMi45NTM2IDMyLjM4NDQiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iNC43MjcxNyIvPgo8cGF0aCBkPSJNMi41MjI0NiAzMi4zODQ0TDMyLjk1MzYgMS45NTMyNyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSI0LjcyNzE3Ii8+Cjwvc3ZnPgo=");
  }
}










@media screen and (min-width: 1200px) {
  .broker-raw {
    border-radius: 20px;
    margin-bottom: 25px;
    padding-right: 10px;
  }
    
  .broker-raw__logo {
    position: relative;
    width: 200px;
    min-height: 60px;
    text-align: center;
  }
  .broker-raw__action {
    flex-shrink: 0;
    width: 230px;
    text-align: right;
  }

  .broker-logo::after {
    color: #969696;
    margin: 0;
    text-transform: lowercase;
  }
  .broker-raw__logo::before {
    content: '';
    position: absolute;
    width: 8px;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, #1E1E1E 0%, #393939 100%); 
  }
  
  .broker-raw__cell {
    display: flex;
    justify-content: center;
    padding: 12px 5px;
    width: 20%;
    position: relative;
  }
  .broker-raw__cell-name {
    position: absolute;
    top: 0;
    width: 80%;
    left: 50%;
    margin: auto;
    background-color: rgba(0, 0, 0, .6);
    opacity: 0;
    transition: .2s;
    will-change: transform, opacity;
    color: #fff;
    border-radius: 8px;
    padding: 4px;
    font-size: 12px;
  }
  .broker-raw:not(:nth-child(1)):hover .broker-raw__cell-name {
    opacity: 1;
    transform: translate(-50%, -100%);
  }


}





@media screen and (min-width: 1500px) {
  
  .reg-rf::after,
  .reg-rf-x::after {
    width: 36px;
    height: 36px;
  }

  .num-value {
    font-size: 18px;
  }
  .broker-action {
    width: 260px;
  }
}









.broker-card {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 36px 9px 40px;
  margin-top: 40px;
}

.broker-card__logo {
  position: absolute;
  left: 10px;
  top: -15px;

  background: #1E1F23;
  border: 1px solid #3E3E3E;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-align: right;
}


.broker-card__action {
  position: absolute;
  bottom: -20px;
  right: -12px;
}

.broker-card__cell {
  width: 25%;
}

.broker-card__cell-name {
  font-family: inherit;
  display: block;
  margin-bottom: 8px;
  height: 26px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}


.broker-card__cell-abs {
  width: auto;
  bottom: 0;
  left: 17px;
  position: absolute;
  display: flex;
  align-items: center;
}

.broker-card__cell-rf {
  font-weight: 300;
  font-size: 14px;
  color: #F8DE5E;
  text-transform: capitalize;
}



@media screen and (max-width: 1199.99px) {
  .reg-rf,
  .reg-rf-x {
    height: 26px;
    margin-bottom: 8px;
  }

  .broker-raw {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 36px 9px 40px;
    margin-top: 40px;
  }
  
  .broker-raw__logo {
    position: absolute;
    left: 10px;
    top: -15px;
  
    background: #1E1F23;
    border: 1px solid #3E3E3E;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    text-align: right;
  }
  
  
  .broker-raw__action {
    position: absolute;
    bottom: -20px;
    right: -12px;
  }
  
  
  .broker-raw__cell-name {
    font-family: inherit;
    display: block;
    margin-bottom: 8px;
    height: 26px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }
  
  
  .broker-raw__cell-rf {
    font-weight: 300;
    font-size: 14px;
    color: #F8DE5E;
    text-transform: capitalize;
  }
  .broker-raw__cell {
    width: 25%;
  }
  
  .broker-raw__cell-abs {
    width: auto;
    bottom: 0;
    left: 17px;
    position: absolute;
    display: flex;
    align-items: center;
  }
  



}
