
.school-title {
	width: 90%;
  display: flex;
  justify-content: center;
	margin: 38px auto 43px;
	padding: 20px;
	border: 2px solid #F8DE5E;
	font-weight: 700;
	font-size: 27px;
	text-transform: uppercase;
}

.school-content__title {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 23px;
}


.partition {
	position: relative;
	display: flex;
	align-items: center;
	margin: 13px auto;
	padding: 11px 30px 11px 0;
	background: #3F3F3F;
  cursor: pointer;
}
.partition::before {
	content:'';
	display: block;
	position: absolute;
	left: 0;
	top: auto;
	bottom: auto;
	width: 15px;
	height: 24px;
	background: #f8de5e;
}

.text17 {
	margin-left: 36px;
	margin-right: auto;
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
}

.text20 {
	margin-bottom: 5px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 16px;
}
.text21 {
	font-weight: 300;
	font-size: 14px;
	margin-bottom: 13px;
}




.school-content {
	color: #000;
	white-space: pre-line;
}






.free-signals {
	padding: 22px 50px 11px;
	margin: 39px auto 0;
	background: rgba(198, 198, 198, 0.29);
	border-radius: 18px;
}

.text19 {
	margin-top: 20px;
	font-weight: 500;
	font-size: 14px;
}



.btn.btn-school {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 254px;
	height: 52px;
	background: #F8DE5E;
	border-radius: 10px;
	font-weight: 600;
	font-size: 22px;
}


.school-container {
	max-width: 1258px;
	margin-left: auto;
	margin-right: auto;
}


.more {
	flex-shrink: 0;
	background: #F8DE5E;
	cursor: pointer;
	margin-left: 20px;
}


@media screen and (max-width: 767px) {

  .more {
    width: 30px;
    height: 30px;
    border-radius: 3px;
  }

  .more::before {
    content: '';
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyMyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMzU0NzMgMTIuNDg4MUwyMC42NiA1LjU5MjA4TTIwLjY2IDUuNTkyMDhMMTEuNzEzMyAxLjM1NDc0TTIwLjY2IDUuNTkyMDhMMTYuNDIyNyAxNC41Mzg5IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjMiLz4KPC9zdmc+Cg==");
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    display: block;
  }
}  

@media screen and (min-width: 768px) {


  .more {
    padding: 15px 50px;
    border-radius: 10px;
  }
  .more::before {
    content: 'смотреть';
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    color: #000;
  }
}


@media screen and (min-width: 992px) {


	.text19 {
		margin-top: 29px;
		font-size: 20px;
	}

	.free-signals {
		display: flex;
		align-items: center;
		background: rgba(217, 217, 217, 0.29);
	}



}




@media screen and (min-width: 768px) {
  .partition {
    padding: 11px 30px 11px 0;
    background: #3F3F3F;
    border-radius: 10px;
  }

  .partition::before {
    left: -21px;
    width: 42px;
  }
}





@media screen and (min-width: 992px) {
  
	.school-title {
		justify-content: center;
		margin: 111px auto 43px;
		padding: 30px;
		height: 151px;
		border-width: 4px;
		font-weight: 700;
		font-size: 62px;
	}

	.text17 {
		margin-left: 56px;
		font-size: 25px;
	}
	.text18 {
		font-size: 25px;
	}


	.text20 {
		font-size: 25px;
	}
	.text21 {
		font-size: 20px;
		margin-bottom: 0; 
	}
}




@media screen and (min-width: 768px) {

  .md--breadcrumbs {
    display: flex;
    font-size: 27px;
    font-weight: 300;
    color: #D9D9D9;
  }
  .to-main {
    display: block;
    margin-right: 10px;
    text-decoration: none;
    color: #D9D9D9;
  }

}

@media screen and (max-width: 767px) {

  .md--breadcrumbs {
    display: none;
  }

}
