.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  opacity: 0.8;
  background: rgba(30, 31, 35, 0.4);
  backdrop-filter: blur(8.5px);
  will-change: background-color, opacity;
  animation: .4s op forwards;
}
@keyframes op {
  from { opacity: 0;}
  to { opacity: 1;}
}

.modal {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  outline: 0;
}
.drawer {
  position: absolute;
  right: 0;
  top: 60px;
  overflow: hidden;
  display: flex;
  outline: 0;
  z-index: 500;
}


.modal {
  flex-direction: column;
}
.modal-container {
  z-index: 3;
  width: 96%;
  height: 100%;
  will-change: transform, opacity;
  animation: .4s mc;
}

@keyframes mc {
  from { opacity: 0; transform: translateX(-100%) }
  to { opacity: 1; transform: translateX(0) }
}

.modal .modal-container {
  max-width: 1600px;
  height: auto;
  margin: auto;
  margin-left: 0;
  max-height: 100%;
  
  display: flex;
  flex-direction: column;
}
.modal-content {
	color: #000;
	padding: 43px 20px 80px 20px;
	background: #FFF;
	border-top-right-radius: 49px;
	border-bottom-right-radius: 49px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.drawer .modal-container {
  margin-left: auto;
  width: 80%;
  max-width: 400px;
}

.modal-head {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 20px 8px;
  border-bottom: 1px solid #b5ccff;
}
.modal-title {
  font-size: 14px;
  color: #666;
}

.modal-content {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
.drawer .modal-content {
  padding: 20px;
}

.modal__close {
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  position: relative;
}
.cross {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 3px;
  background: transparent;
  cursor: pointer;
  transition: .4s;
  transform: scale(0.8);
  margin-left: auto;
}
.cross:hover {
  transform: scale(1);
}
.cross:before,
.cross:after {
  content: '';
  position: absolute;
  display: block;
  width: 60px;
  left: 0px;
  top: 37px;
  height: 10px;
  background: #F8DE5E;
}
.cross:before {
  transform: rotate(45deg);
}
.cross:after {
  transform: rotate(-45deg);
}

@media screen and (min-width: 1000px) {
  .modal .modal-container {
    margin-left: auto;
  }
  
	.modal-content {
		border-radius: 49px;
		padding: 73px 55px;
	}
}