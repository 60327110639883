

.strategy-card {
  display: block;
  background-color: #2A2B2F;
  color: #FFF;
  border-radius: 15px;
  padding: 20px 25px 10px;
}
.strategy-card__btn {
  background-color: #FFF;
  color: #000;
  text-decoration: none;
  display: block;
  padding: 0.5em 2em;
  font-size: 14px;
  border-radius: 20px;
}
.str-num {
  font-size: 14px;
  bottom: 10px;
  left: 25px;
}




.grey-text {
  color: #4B4B4B;
}
.fw6 {
  font-weight: 600;
}
.mb12{margin-bottom: 12px;}
.mb60{margin-bottom: 60px;}
.mb25{margin-bottom: 25px;}

.bg-white {
  background: #fff;
}
.black-text {
  color: #000;
}
.py100 {
  padding-top: 100px;
  padding-bottom: 100px;
}


.download-btn {
  color: #000;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  padding: 1em;
  border-radius: 14px;
  border: 2px solid #FF7A00;
  background: #FFF;
  cursor: pointer;
}

.download-btn::before {
  content: '';
  width: 40px;
  height: 40px;
  display: block;
  margin-right: 20px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Im04IDE3IDQgNCA0LTQiPjwvcGF0aD4KICA8cGF0aCBkPSJNMTIgMTJ2OSI+PC9wYXRoPgogIDxwYXRoIGQ9Ik0yMC44OCAxOC4wOUE1IDUgMCAwIDAgMTggOWgtMS4yNkE4IDggMCAxIDAgMyAxNi4yOSI+PC9wYXRoPgo8L3N2Zz4=);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}