#signals {
	background-image: url('/public/signals-bg-lg.svg');
	background-position: left top;
	background-size: contain;
	background-repeat: no-repeat;
	padding-bottom: 50px;
}

@media screen and (min-width: 1400px) {
	#signals {
		background-size: 100%;
	}
}


.text22 {
	font-weight: 400;
	font-size: 20px;
	text-transform: uppercase;
	background: linear-gradient(93.09deg, #F8DE5E 25.56%, #FF7A00 52.56%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-bottom: 40px;
	margin-top: 10px;
}





.tab {
	position: relative;
	flex-grow: 1;
	cursor: pointer;
	text-align: center;
	padding-bottom: 16px;
}

.tab__inner {
	position: relative;
	z-index: 2;
	background: #fff;
	border: 2px solid #FFF;
	border-radius: 5px;
	padding: 7px;
	transition: border-radius .3s;
	will-change: border-radius;
}

.inner-text {
	position: relative;
	z-index: 2;
	color: #000;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	user-select: none;
}
@media screen and (min-width: 360px) {
  .inner-text {
    font-size: 14px;
  }
}

.tab__inner::before {
	left: 0;
	right: 0;
	bottom: -16px;
}
.tab__inner::after {
	left: 2px;
	right: 2px;
	bottom: -14px;
}

.tab__inner::after,
.tab__inner::before {
	background: #FFF;
	transition: .3s;
	transition-delay: .3s;
	transform-origin: top;
	transform: scaleY(0);

	content: '';
	display: block;
	position: absolute;
	height: 14px;
	-webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
	clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}
.tab.active .tab__inner::after,
.tab.active .tab__inner::before {
	transition-delay: 0s;
	transform: scaleY(1);
}
.tab.active .tab__inner::after,
.tab.active .tab__inner {
	background-image: linear-gradient(to left, #F8DE5E, #FF7A00);
}

.signals-tabs {
	gap: 8px;
}

@media screen and (min-width: 992px) {
    
	.tab {
		position: relative;
		padding-bottom: 60px;
	}
	.tab__inner {
		height: 118px;
		border-radius: 0;
		border-width: 4px;

		
    display: flex;
    align-items: center;
    justify-content: center;
	}
	
  .signals-tabs {
    gap: 14px;
  }
	.inner-text {
		font-size: 25px;
	}
	.tab::after {
		content: 'смотреть';
		display: block;
		text-transform: uppercase;
		margin: auto;
		color: #000;

		left: 10%;
		right: 10%;
		
		position: absolute;
		bottom: 0;
		padding: 13px 25px;
		background: #F8DE5E;
		font-weight: 700;
		font-size: 28px;

		transition: .3s ease-out;
		will-change: transform;
	}
	
	.tab__inner::before {
		bottom: -33px;
		height: 30px;
	}
	.tab__inner::after {
		left: 4px;
		right: 4px;
		bottom: -29px;
		height: 30px;
	}

	.tab.active .tab__inner {
		border-radius: 10px;
	}
	.active.tab::after {
		transform: translateY(-100%);
	}
}

@media screen and (min-width: 1200px) {
	
  .signals-tabs {
    gap: 32px;
  }
}

.signals-section {
	position: relative;
	background: #fff;
  color: #000;
	padding: 44px 0;
}




.text3 {
	color: #000;
	font-weight: 700;
	font-size: 20px;
	margin: 0;
	margin-bottom: 10px;
}

.text4 {
	color: #000;
	font-size: 14px;
	margin: 0;
}

.arrow {
	display: none;
}

.signal-text-line {
	padding-left: 18px;
}
.signal-text-line:before {
	content: '';
}

@media screen and (min-width: 992px) {

	.signal-text-line {
		padding-left: 30px;
		padding-top: 30px;
	}
	.signal-text-line:before {
		height: 130px;
	}
	.arrow {
		margin-top: 60px;
		display: block;
	}
	.arrow > svg {
		width: 100%;
	}
  .text3 {
    font-size: 40px;
  }

  .text4 {
    font-size: 24px;
  }

}




.guide-video-container {
	position: relative;
	background: #FF7A00;
	background: linear-gradient(180deg, #F8DE5E 0%, #FF7A00 100%);
	border-radius: 12px;
	padding: 5px;
	margin-bottom: 14px;
	margin-top: 50px;
}

.guide-video-bg {
	background: linear-gradient(144.43deg, #4F4E4E 20.85%, #101010 98.55%);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 26px;
	text-align: center;
}
.guide-video {
	width: 100%;
	min-height: 200px;
}
.guide-video-link {
	display: block;
	position: absolute;
	z-index: 2;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: transparent;
}
.guide-video-title {
	font-weight: 700;
	font-size: 16px;
	line-height: 112.8%;
  color: #FFF;
}
.guide-youtube {
	margin: 15px;
	height: 31px;
}
.text23 {
	font-size: 14px;
	line-height: 112.8%;
	color: #000;
}


@media screen and (min-width: 768px){
	.h100--md {
		height: 100%;
	}
}
@media screen and (min-width: 992px) {
		
	.guide-video-container {
		border-radius: 22px;
		margin-top: 0;
	}
	.guide-video-bg {
		border-radius: 18px;
	}
	.guide-video {
		min-height: 300px;
	}
	
	.text23 {
		font-size: 32px;
		text-align: center;
	}

	.guide-youtube {
		height: 58px;
		margin: 30px;
	}
	.guide-video-title {
		font-size: 32px;
	}
}

@media screen and (max-width: 767px) {
	.md--mb15 {
		margin-bottom: 15px;
	}
	.md--cola {
		flex-grow: 1;
		width: auto;
	}
}






.list-title {
	font-weight: 700;
	font-size: 20px;
	color: #F8DE5E;
	text-transform: uppercase;
}

@media screen and (min-width: 992px){
  
	.list-title {
		color: #fff;
		font-size: 40px;
	}


}




.list-gifts {
	padding: 47px 0 64px 0;
	background: #34353C;
}

.text6 {
	font-weight: 700;
	font-size: 39px;

}

.text7 {
	color: #f8de5e;
}

.gift {
	font-weight: 500;
	font-size: 39px;
}

.orange {
	color: #ff7a00;
}


.tg-bot {
	text-transform: uppercase;
	color: #67b0ff;
}

.website-link {
	color: #63db39;
}

.link-verify {
	text-transform: uppercase;
	color: #f8de5e;
}
.shot-img {
	width: 100%;
	display: block;
	margin-bottom: 40px;
}
.number-screen {
	position: absolute;
	top: -19px;
	left: -19px;
	width: 91px;
	height: 91px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border-radius: 50%;
	color: #000;
	font-weight: 700;
	font-size: 39px;
}



.reviews-container {
	width: 100%;
	margin-top: 20px;
}
.reviews {
	padding-top: 32px;
	padding-bottom: 32px;
	width: 100%;
	background: #fff;
	border-radius: 25px;
	overflow: hidden;
	transform: scaleY(0);
	transform-origin: top;
	opacity: 0;
  animation: fadeScale .6s ease forwards;
	will-change: transform, opacity;
}
.reviews-wrapper {
	display: flex;
	gap: 36px;
	padding-left: 15px;
	width: 100%;
	overflow-x: auto;
}
@keyframes fadeScale {
  0% {
		opacity: 0;
    transform: scaleY(0);
  }
  to {
		opacity: 1;
    transform: scaleY(1);
  }
}
.reviews__bottom {
	padding: 37px 27px 37px 34px;
}
.reviews__text {
	margin-bottom: 20px;
	color: #fff;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	text-transform: uppercase;
}
.review__image {
	display: block;
	width: 150px;
	height: 320px;
	flex-shrink: 0;
}

.reviews__btn {
	display: inline-block;
	padding: 18px 45px;
	background: #29A2F9;
	border-radius: 15px;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	text-transform: uppercase;
	text-decoration: none;
	color: #FFFFFF;
}

@media screen and (min-width: 992px) {
	.reviews-container {
		order: 3;
	}
}








.btn-rounded {
	display: block;
	width: 240px;
	margin: 40px auto;
	text-align: center;
	border-radius: 40px;
	padding: 20px;
	text-decoration: none;
	font-size: 18px;
	color: #000;
	background-color: #F8DE5E;
}


@media screen and (max-width: 992px) {
	
	.signals-section {
		padding-bottom: 200px;
	}
	.btn-rounded {
		position: absolute;
		bottom: 50px;
		left: 0;
		right: 0;
	}

}