.fx-logo {
	display: block;
	width: 100%;
	max-width: 590px;
}

.bg2 {
  background-color: #3D3D3D;
}
.bg3 {
  background-color: #2A2B2F;
}

#home-page {
	background-position: top;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url('/public/main-bg.svg');
}

.home {
	padding-top: 70px;
	padding-bottom: 40px;
}
@media screen and (min-width: 992px) {
	
	#home-page {
		background-image: url('/public/main-bg-lg.svg');
	}
	.home {
		display: flex;
		align-items: center;
	}
	.home__logo {
		width: 100%;
		max-width: 45%;
		order: 2;
		padding: 40px;
		text-align: center;
	}
	.home__content {
		width: 100%;
		max-width: 65%;
		padding: 40px 60px 40px 0;
	}
}




.line {
	position: relative;
	display: inline-block;
}

.line:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 10px;
	height: 100%;
    background: linear-gradient(180deg, #F8DE5E 0%, #FF7A00 100%); 
}


@media screen and (max-width: 767px) {
	.line-mobile {
		padding-left: 24px;
	}
	.line-mobile::before {
		content: '';
	}
}
@media screen and (min-width: 768px) {
	.line-desktop {
		padding-left: 40px;
	}
	.line-desktop::before {
		content: '';
	}
}



.card {
	flex-grow: 1;
	position: relative;
	padding: 23px 34px;
	background: #45474B;
	border-radius: 27px;
	margin-bottom: 24px;
}

.card-figure {
	display: inline-block;
	font-weight: 700;
	font-size: 42px;
	background: linear-gradient(141.15deg, #F8DE5E 22.31%, #FF7A00 82.79%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.card-text {
	font-weight: 500;
	font-size: 20px;
	color: #FFF;
}

.card-button {
	position: absolute;
	border: 0;
	bottom: -8px;
	right: -10px;
	padding: 13px 33px;
	background: linear-gradient(265.31deg, #F8DE5E 8.74%, #FF7A00 74.68%);
	border-radius: 4px;
	color: #fff;
	font-weight: 700;
	font-size: 13px;
	text-decoration: none;
	text-transform: uppercase;
}




@media screen and (min-width: 1500px) {
	.card {
		padding: 28px 41px;
		margin: 0;
		height: 100%;
		border-radius: 33px;
	}

	.card-figure {
		font-size: 50px;
	}

	.card-text {
		font-size: 25px;
	}

	.card-button {
		bottom: -10px;
		right: -12px;
		padding: 14px 40px;
		border-radius: 5px;
		font-size: 16px;
	}
}



.footer {
	background-color: #18181C;
	padding: 60px 0;
	color: #9F9F9F;
}


.fx-color {
	color: #F8DE5E;
}

.strategies__best {
	font-size: 18px;
	margin-bottom: 25px;
}



@media screen and (min-width: 992px) {
	.strategies__best {
		max-width: 640px;
		margin-top: 16px;
		padding-top: 32px;
		position: relative;
		padding-right: 40px;
	}
	.strategies__best::before {
		background-position: right;
		background-size: auto;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzYwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgNzYwIDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNzU5LjA2MSAxMy4wNjA3Qzc1OS42NDYgMTIuNDc0OSA3NTkuNjQ2IDExLjUyNTEgNzU5LjA2MSAxMC45MzkzTDc0OS41MTUgMS4zOTM0Qzc0OC45MjkgMC44MDc2MTEgNzQ3Ljk3OSAwLjgwNzYxMSA3NDcuMzkzIDEuMzkzNEM3NDYuODA4IDEuOTc5MTkgNzQ2LjgwOCAyLjkyODkzIDc0Ny4zOTMgMy41MTQ3Mkw3NTUuODc5IDEyTDc0Ny4zOTMgMjAuNDg1M0M3NDYuODA4IDIxLjA3MTEgNzQ2LjgwOCAyMi4wMjA4IDc0Ny4zOTMgMjIuNjA2NkM3NDcuOTc5IDIzLjE5MjQgNzQ4LjkyOSAyMy4xOTI0IDc0OS41MTUgMjIuNjA2Nkw3NTkuMDYxIDEzLjA2MDdaTTAgMTMuNUg3NThWMTAuNUgwVjEzLjVaIiBmaWxsPSIjM0UzRTNFIi8+Cjwvc3ZnPgo=");
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 22px;
	}


	.strategies__best-wrapper {
		display: flex;
		align-items: flex-start;
		gap: 40px;
	}
}







.btn3 {
	max-width: 450px;
	margin: auto;
	margin-bottom: 20px;
	flex-shrink: 0;
	color: #FFF;
	height: 140px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-transform: uppercase;
	text-align: center;
	padding: 40px;
	background-color: #3D3D3D;
	border: 2px solid #3D3D3D;
	border-radius: 15px;
	font-size: 24px;
	text-decoration: none;
	font-weight: 700;
	transition: border .3s;
}
.btn3:hover {
	border-color: #F8DE5E;
}
.fz25 {
	font-size: 25px;
}
.rg30 {
	row-gap: 30px;
}






















/*======================================================================== SHORT =========*/

.dib {
	display: inline-block;
}

.jcb,
.jca,
.jcc,
.aic,
.df {
	display: flex;
}
.jcc {
	justify-content: center;
}
.jcb {
	justify-content: space-between;
}
.jca {
	justify-content: space-around;
}
.aic {
	align-items: center;
}
.fc {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
}
.db {
	display: block;
}
.mla {
	margin-left: auto;
}
.mra {
	margin-right: auto;
}
.mxa {
	margin-left: auto;
	margin-right: auto;
}
.ma {
	margin: auto;
}
.aic {
	display: flex;
	align-items: center;
}
.tac {
	text-align: center;
}
.tar {
	text-align: right;
}
.jcc {
	display: flex;
	justify-content: center;
}
.jca {
	display: flex;
	justify-content: space-around;
}
.jcb {
	display: flex;
	justify-content: space-between;
}
.tdn {
	text-decoration: none;
}

.dn {
	display: none;
}
.row {
  display: flex;
  flex-wrap: wrap;
}


.tx-balance {
    text-wrap: balance;
}
.ws {
	white-space: nowrap;
}
.gap-col16 {
	margin-left: -16px;
	margin-right: -16px;
}

.gap-col16 > * {
	padding-left: 16px;
	padding-right: 16px;
}

.gap-col4 {
	margin-left: -4px;
	margin-right: -4px;
}

.gap-col4 > * {
	padding-left: 4px;
	padding-right: 4px;
}

.col6 {
	width: 100%;
	max-width: 25%;
	flex-basis: 25%;
}


.col18 {
	width: 100%;
	max-width: 75%;
	flex-basis: 75%;
}

.col8--md,
.col8--lg,

.col7--lg,

.col12--xs,
.col12--sm,
.col12--md,
.col12--lg,

.col10--md,
.col10--lg,

.col24,
.col18--lg,
.col18--md,

.col16--xs,
.col16--lg,
.col16--sm,
.col16--md,

.col20--xs,
.col20--sm,
.col20--lg,
.col8--xs,
.col8--sm,
.col8--md,
.col8--lg,
.col8--xl,
.col6--xl,
.col6--lg,
.col6--md {
  width: 100%;
}

.col8 {
  width: 100%;
  max-width: 33.333%;
  flex-basis: 33.333%;
}
.col12 {
	width: 100%;
	max-width: 50%;
	flex-basis: 50%;
}

.col16 {
	width: 100%;
	max-width: 66.666%;
	flex-basis: 66.666%;
}

@media screen and (min-width: 410px) {
  .col12--xs {
    max-width: 50%;
    flex-basis: 50%;
  }
  .col16--xs {
    max-width: 66.666%;
    flex-basis: 66.666%;
  }
  .col20--xs {
    max-width: 83.333%;
    flex-basis: 83.333%;
  }
}

@media screen and (min-width: 560px) {
  .col12--sm {
    max-width: 50%;
    flex-basis: 50%;
  }
  .col16--sm {
    width: 66.666%;
    flex-basis: 66.666%;
  }
  .col20--sm {
    width: 83.333%;
    flex-basis: 83.333%;
  }
  
	.df--sm {
		display: flex;
	}
}

@media screen and (min-width: 768px) {
	.col8--md {
		max-width: 33.333%;
		flex-basis: 33.333%;
	}
	.col10--md {
		max-width: 41.666%;
		flex-basis: 41.666%;
	}
	.col12--md {
		max-width: 50%;
		flex-basis: 50%;
	}
	.col16--md {
		max-width: 66.666%;
		flex-basis: 66.666%;
	}
	.col18--md {
	    max-width: 75%;
	    flex-basis: 75%;
	}

	.col6--md {
		max-width: 25%;
		flex-basis: 25%;
	}

	.col24--md {
		max-width: 100%;
		flex-basis: 100%;
	}

	.reverse--md {
		flex-direction: row-reverse;
	}

	.df--md {
		display: flex;
	}
}

@media screen and (min-width: 992px) {
	.col10--lg {
		max-width: 41.666%;
		flex-basis: 41.666%;
	}
  .col8--lg{
    max-width: 33.333%;
    flex-basis: 33.333%;
  }
  .col7--lg{
    max-width: 29%;
    flex-basis: 29%;
  }
  .col6--lg {
    max-width: 25%;
    flex-basis: 25%;
  }
  .col18--lg {
    max-width: 75%;
    flex-basis: 75%;
  }
  .col16--lg {
    max-width: 66.666%;
    flex-basis: 66.666%;
  }
  .col12--lg {
    max-width: 50%;
    flex-basis: 50%;
  }
  .col20--lg {
    max-width: 83.333%;
    flex-basis: 83.333%;
  }

  .dn--lg {
  	display: none;
  }
  .db--lg {
  	display: block;
  }
  .nowrap--lg {
    flex-wrap: nowrap;
  }

	.df--lg {
		display: flex;
	}
  .jcb--lg {
    justify-content: space-between;
  }
    .aic--lg {
        align-items: center;
    }

	.tac--lg {
		text-align: center;
	}
}



@media screen and (min-width: 1300px) {
	.col8--xl {
		width: 33.333%;
    flex-basis: 33.333%;
	}

	.col6--xl {
    max-width: 25%;
    flex-basis: 25%;
  }

	.df--xl {
		display: flex;
	}
}



.fw3 {
	font-weight: 300;
}
.fw4 {
	font-weight: 400;
}

.fw5 {
	font-weight: 500;
}

.fw6 {
	font-weight: 600;
}

.fw7 {
	font-weight: 700;
}

.fw8 {
	font-weight: 800;
}
.uppercase {
	text-transform: uppercase;
}

.relative {
	position: relative;
}

.hidden-x {
	overflow-x: hidden;
}

.hidden {
	overflow: hidden;
}

.x-auto {
	overflow-x: auto;
}

.box-shadow {
	box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.18);
}

.bg-cover {
	background-size: cover;
}

.fs {
	flex-shrink: 0;
}

.bb {
	border-bottom: 1px solid #E0E0E0;
}

.w-full {
	width: 100%;
}
.o4 {
    opacity: .4;
}
.o6 {
    opacity: .8;
}
.o8 {
    opacity: .8;
}











