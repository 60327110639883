

.brokers {
  width: 100%;
  padding-right: 5px;
  margin-bottom: 140px;
}


@media screen and (min-width: 1200px) {

  .table-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
  }
  .broker-col {
    text-transform: uppercase;
    padding: 15px 5px;
    font-size: 12px;
    text-align: center;
    width: 20%;
    flex-shrink: 0;
  }
  .broker-col--logo {
    width: 200px;
    font-weight: 700;
    font-size: 14px;
  }
  .broker-col--action {
    width: 230px;
  }
}


@media screen and (min-width: 1500px) {
  
  .broker-col {
    padding: 15px;
  }
  .broker-col--action {
    width: 260px;
  }
}

@media screen and (max-width: 1199.99px) {
  .table-head {
    display: none;
  }
}







.text10 {
text-transform: uppercase;
font-size: 22px;
color: #F8DE5E;
font-weight: 400;
margin-bottom: 24px;
}
.text11 {
font-weight: 300;
}

.choice {
  display: flex;
  justify-content: space-around;
	margin-bottom: 60px;
}

.choice-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
	min-height: 44px;
  background: none;
  color: #fff;
}
.active.choice-tab {
  color: #000;
  background: #f8de5e;
}
.text15-block {
  padding: 40px 25px;
}
.line3 {
  position: relative;
  display: block;
  width: 100%;
  padding-left: 16px;
}
.how-chose {
  max-width: 621px;
}
.line3:before {
  position: absolute;
  left: 0;
  content: " ";
  width: 4px;
  height: 100%;
  background: #F8DE5E;
}







.recommend {
	max-width: 800px;
	padding: 25px 0 55px 0;
  margin-top: 75px;
	margin-left: auto;
	margin-right: auto;
}
.recommend-broker {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 10px;
	background: #3D3D3D;
	border-radius: 8px;
	margin-bottom: 20px;
}
.recommend-broker-logo {
	margin-bottom: 15px;
	width: 70%;
	max-width: 232px;
}
@media screen and (min-width: 1400px)  {
	.recommend-broker-logo {
		margin-bottom: 30px;
	}
}







@media screen and (min-width: 768px) {
	.text15-block {
		padding: 40px 50px 0;
	}
}


.choice-tab {
	border: 1px solid #F8DE5E;
	border-radius: 5px;
	font-size: 14px;
	cursor: pointer;
}  


@media screen and (max-width: 767px) {

	.text11 {
	    font-size: 19px;
	}




		



}


@media screen and (min-width: 768px) {
  /* .home__inner {
    display: flex;
    flex-direction: row-reverse;
  }

  .home	{
    padding: 206px 0 86px;
  } */



  .button-see {
  }
	.main-top-content {
		position: relative;
		padding: 25px 0;
		background: #FFF;
		box-shadow: -49px 27px 51px rgb(0 0 0 / 62%);
	}
  .main-top-content__inner {
		display: flex;
		align-items: center;
		gap: 20px;
  }
	.line4 {
		position: relative;
		margin-left: 40px;
		text-transform: uppercase;
		font-size: 20px;
		line-height: 24px;
		color: #000;
	}
	.number {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 144px;
		bottom: -33px;
		width: 66px;
		height: 66px;
		border-radius: 50%;
		background: #393A40;
		font-size: 35px;
		line-height: 42px;
		color: #FFF;
	}

	.text10 {
		font-size: 29px;
	}

	.text11 {
		font-size: 29px;
	}

	.choice {
		margin-bottom: 100px;
	}
	.choice-tab {
		max-width: 285px;
    min-height: 66px;
		border-width: 2px;
		border-radius: 12px;
		font-size: 24px;
	}


  .recommend-container {
		border-radius: 20px;
  }
	.text15-block {
		padding: 40px 80px;
	}

  .line3 {
    display: flex;
    align-items: center;
  }




}	

.open-account2 {
	background: #FFF;
}



