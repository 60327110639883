



/*======================================================================== RESET =========*/
*,*:before,*:after{
  box-sizing: border-box;
}
body {
  margin: 0;
}

p {
	margin-top: 0;
	margin-bottom: 1em;
}
h1, h2, h3, h4, h5 {
	margin: 0;
}
@media screen and (max-width: 560px) {
	ol.text-mid {
		padding-left: 1rem;
	}
}

/*======================================================================== BASIC =========*/

body {
  background-color: #1E1F23;
  color: #fff;
  font-family: 'Inter', sans-serif;
}

.section {
	padding-top: 100px;
	padding-bottom: 100px;
	width: 100%;
	display: block;
}
.bg-white {
	background-color: #fff;
	color: #000;
}
.color-primary {
	color: #F8DE5E;
}

.btn {
	display: inline-block;
	text-align: center;
	color: #000;
	text-decoration: none;
	cursor: pointer;
	user-select: none;
	border: 0;
	font-family: inherit;
	text-transform: uppercase;
}
.btn-primary {
	display: inline-block;
	background: #F8DE5E;
	border-radius: 5px;
	padding: 6px 10px;
	font-weight: 700;
	font-size: 11px;
}

.btn-primary-big {
  background: #F8DE5E;
  border-radius: 7px;
  text-transform: uppercase;
  font-weight: 500;
	padding: 7px 20px;
	font-size: 18px;
}
@media screen and (min-width: 768px) {
	.btn-primary-big {
		padding: 10px 30px;
		font-size: 20px;
	}
}
@media screen and (min-width: 1500px) {
	.btn-primary {
		padding: 8px 30px;
		font-size: 18px;
	}
	.btn-primary-big {
		padding: 10px 30px;
		font-size: 24px;
	}
}




.title1 {
	font-size: 28px;
	margin-bottom: 5px;
	text-transform: uppercase;
}
.title2 {
	font-size: 20px;
	text-transform: uppercase;
}
.text-mid {
	font-size: 16px;
}
.text {
	font-size: 16px;
}


@media screen and (min-width: 410px) {
	.title1 {
		font-size: 32px;
	}
	.title2 {
		font-size: 25px;
	}
	.text-mid {
		font-size: 18px;
	}
}
@media screen and (min-width: 768px) {
	.title1 {
		font-size: 32px;
	}
	.title2 {
		font-size: 28px;
	}
}
@media screen and (min-width: 992px) {
	.title1 {
		font-size: 35px;
	}
	.title2 {
		font-size: 32px;
	}
}
@media screen and (min-width: 1400px) {
	.title1 {
		font-size: 50px;
	}
	.title2 {
		font-size: 35px;
	}
	.text-mid {
		font-size: 20px;
	}
}

/*======================================================================== GRID =========*/


.container {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 992px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (min-width: 1100px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1080px;
  }
}
@media screen and (min-width: 1500px) {
  .container {
    max-width: 1280px;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1360px;
  }
}



/*======================================================================== SHORT =========*/

.w-full {
	width: 100%;
}


.relative {
	position: relative;
}
.absolute {
  position: absolute;
}
.sticky {
  position: sticky;
}


.mra {
	margin-right: auto;
}
.ma {
	margin: auto;
}
.mb15 {
	margin-bottom: 15px;
}
.mb30 {
	margin-bottom: 30px;
}
.mb50 {
	margin-bottom: 50px;
}

